/*******************************************************************************
* Switch
***************************************************************************** */

/*
 * 1. Leaving this here because it's declared in the DOM.
 */

/* Block ******************************************************************** */
.c-switch {
  align-items: center;
  display: inline-flex;
}

/* Element ****************************************************************** */
.c-switch__input {
  /* 1 */
}

.c-switch__label {
  flex: 0 0 auto;
  margin-right: 1em;
  user-select: none;
}

.c-switch__switch-toggle {
  border-radius: 100px;
  height: 1em;
  user-select: none;
  width: 1em;

  /* cosmetics */
  transition: box-shadow .1s;
  background-color: var(--color-utility-pure);
}

.c-switch__switch-feedback {
  flex: 0 0 auto;
  margin-left: .5em;
  font-size: .8em;

  /* cosmetics */
  color: var(--color-utility-dove);
}

.c-switch__spacer {
  flex: 1 1 100%;
}

.c-switch__switch-background {
  border-radius: 100px;
  flex: 0 0 auto;
  padding-bottom: 1px;
  padding-left: calc(1em + 1px);
  padding-right: 1px;
  padding-top: 1px;
  transition: padding .1s;
  user-select: none;

  /* cosmetics */
  background-color: var(--color-utility-dove);
}

.c-switch__switch-background:focus {
  outline: none;
}

.c-switch__switch-background:hover .c-switch__switch-toggle {
  /* cosmetics */
  box-shadow: 0 0 0 2px var(--color-utility-dove);
}

.c-switch__switch-background:focus .c-switch__switch-toggle {
  /* cosmetics */
  box-shadow: 0 0 0 2px var(--color-primary-cool);
}

/* Modifier **************************************************************** */
.c-switch--block {
  display: flex;
}

.c-switch__switch-background--active {
  padding-left: 1px;
  padding-right: calc(1em + 1px);

  /* cosmetics */
  background-color: var(--color-primary-cool);
}

.c-switch__switch-feedback--active {
  /* cosmetics */
  color: var(--color-primary-cool);
}
