/*******************************************************************************
* Outline Item
***************************************************************************** */

/* Notes:
 * 1. The containing element needs to provide the styling here.
 * 2. Override some default user-agent styles.
 * 3. Currently Selected Outline Item
 */

/* Block ******************************************************************** */
.c-outline-item {
  overflow-x: hidden;
  display: flex;

  /* cosmetics */
  border-radius: var(--radius-corners);
  transition: background-color .1s, color .1s;
}

.c-outline-item:focus {
  outline: none;
}

/* Element ****************************************************************** */
.c-outline-item__button {
  background-color: transparent; /* 2 */
  border-width: 0; /* 2 */
  color: inherit; /* 2 */
  cursor: default;
  display: inline;
  flex: 1 1 100%;
  overflow-x: hidden;
  padding-bottom: .35em;
  padding-left: .75em;
  padding-right: .5em;
  padding-top: .4em;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.c-outline-item__button:focus {
  /* cosmetics */
  border-radius: var(--radius-corners);
  box-shadow: inset 0 0 0 1px var(--color-primary-cool);
  outline: none;
}

.c-outline-item__jump-description {
  display: inline-block;
  width: 0;
  overflow-x: hidden;
  white-space: nowrap;
}

.c-outline-item__jump {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  flex: 0 0 auto;
  padding-left: .65em;
  padding-right: .55em;

  /* cosmetics */
  border-bottom-right-radius: var(--radius-corners);
  border-left: 1px solid var(--color-primary-cool-semi-transparent);
  border-top-right-radius: var(--radius-corners);
  color: var(--color-primary-cool);
  font-weight: bold;
}

/* Modifier ***************************************************************** */
.c-outline-item--current,
.c-outline-item--current.c-outline-item:hover {
  /* 3 */

  /* cosmetics */
  background-color: var(--color-primary-cool-semi-transparent);
  color: var(--color-utility-black);
}
