/*******************************************************************************
* Split View
***************************************************************************** */

/* Notes:
 * 1. Aim up and to the right
 * 2. scootch out a bit
 */

/* Block ******************************************************************** */
.c-link {
  /* cosmetics */
  color: var(--color-primary-cool);
  box-shadow: inset 0 -1px 0 0 var(--color-primary-cool);
}

.c-link:hover {
  /* cosmetics */
  box-shadow: inset 0 -2px 0 0 var(--color-primary-cool);
}

.c-link__new-tab-icon {
  font-size: 85%;
  display: inline-block;
  transform: rotate(-40deg); /* 1 */
  position: relative;
  left: .2em; /* 2 */
}
