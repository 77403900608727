/*******************************************************************************
* Outline
***************************************************************************** */

/* Notes:
 * 2. Webkit Scrollbars
 * 3. Scroll Snapping
 */

/* Block ******************************************************************** */
.c-outline {
  flex: 0 0 20em;
  font-size: .8em;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-left: .5em;
  padding-right: .3em;
  padding-top: .5em;
  user-select: none;

  /* cosmetics */
  background-color: var(--color-primary-cool-light);
  scrollbar-color: var(--color-primary-cool-semi-transparent) var(--color-primary-cool-light);
  scrollbar-width: thin;
  transition: box-shadow .2s;
}

.c-outline::-webkit-scrollbar {
  /* 2 */

  /* cosmetics */
  width: 8px;
}

.c-outline::-webkit-scrollbar-track {
  /* 2 */
}

.c-outline::-webkit-scrollbar-thumb {
  /* 2 */

  /* cosmetics */
  background-color: var(--color-primary-cool-semi-transparent);
  border-radius: var(--radius-corners);
  border: 1px solid var(--color-primary-cool-light);
}

/* Element ****************************************************************** */
.c-outline__spread-list {
  list-style: none;
  margin-top: 0;
  padding: 0;
}

/* Modifier ***************************************************************** */
.c-outline--scroll-snap {
  /* 3 */
  scroll-snap-type: y mandatory;
}
