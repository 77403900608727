/*******************************************************************************
* Menu
***************************************************************************** */

/* Notes:
 * 1. Leaving this hear for future development and reference.
 */

/* Block ******************************************************************** */
.c-menu {
  display: flex;
  font-size: .9em;
  padding-left: .5em;

  /* cosmetics */
  background-color: var(--color-utility-pure);
  border-bottom: 2px solid var(--color-utility-milk);
}

/* Elements ***************************************************************** */
.c-menu__item {
  position: relative;
}

.c-menu__item-button {
  background-color: transparent;
  border: none;
  display: block;
  margin: 0;
  outline: none;
  padding: .3em .6em;
  user-select: none;
}

.c-menu__item-button:hover,
.c-menu__item-button:focus {
  /* cosmetics */
  background-color: var(--color-utility-milk);
}

.c-menu__item-button--active,
.c-menu__item-button:hover.c-menu__item-button--active,
.c-menu__item-button:focus.c-menu__item-button--active {
  background-color: var(--color-primary-cool);
  color: var(--color-primary-cool-light);
}

.c-menu__dropdown {
  left: 0;
  list-style: none;
  margin: 0;
  min-width: 10em;
  padding: 0;
  position: absolute;
  top: 100%;
  z-index: 1;

  /* cosmetics */
  background-color: var(--color-utility-pure);
  border-bottom-left-radius: var(--radius-corners);
  border-bottom-right-radius: var(--radius-corners);
  border-top: 1px solid var(--color-utility-milk);
  box-shadow: 0 .2em .2em 1px var(--color-effect-ghost);
  padding-bottom: .3em;
  padding-top: .3em;
}

.c-menu__divider {
  margin: 0;
  padding-bottom: .2em;
  margin-bottom: .2em;

  /* cosmetics */
  border-bottom: 1px solid var(--color-utility-milk);
}

.c-menu__button {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  margin: 0;
  min-height: 2em;
  outline: none;
  padding: .3em 1em;
  text-align: left;
  user-select: none;
  white-space: nowrap;
  width: 100%;
}

.c-menu__button:hover,
.c-menu__button:active,
.c-menu__button:focus {
  /* cosmetics */
  background-color: var(--color-primary-cool);
  color: var(--color-primary-cool-light);
}

.c-menu__button-text {
  flex: 1 1 100%;
  margin-right: 1.5em;
}

.c-menu__hotkey {
  flex: 0 0 auto;
  font-size: .9em;
}
