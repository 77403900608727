/*******************************************************************************
* About Modal
***************************************************************************** */

/* Notes:
 * 1. Leaving this here for future development and reference.
 */

/* Block ******************************************************************** */
.c-about { /* 1 */ }

/* Elements ***************************************************************** */
.c-about__title {
  display: block;
  width: 100%;
  text-align: center;
}
