/*******************************************************************************
* Writer
***************************************************************************** */

/* Notes:
 * 1. see also src/editor/components/codemirror/CodeMirror.css for another
 *    important calc. as well as isola splitview
 * 2. Leving for future development.
 */

/* Block ******************************************************************** */
.c-writer { /* 2 */ }

/* Element ****************************************************************** */
.c-writer__editor-container {
  display: flex;
  height: calc(100vh - 1.71em); /* 1 */
  overflow: hidden;
  position: relative;

  /* cosmetics */
  background-color: var(--color-utility-milk);
}

.c-writer__editor {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  z-index: 0;

  /* cosmetics */
  background-color: var(--color-utility-pure);
}

.c-writer__toolbar {
  flex: 0 0 auto;
  z-index: 1;
}

.c-writer__text-editor {
  flex: 1 1 100%;
  position: relative;
  z-index: 0;
}

.c-writer__metrics-container {
  position: relative;
}

/* modifier ***************************************************************** */
.c-writer__editor-container--insights-open {
  height: calc(100vh - 15em); /* 1 */
}
