/*******************************************************************************
* Tool Tip
***************************************************************************** */

/* Notes:
 * 1. Sets the time before tooltip appears.
 * 3. PERF: Manipulating the opacity here to remove what would be a persistant
 *    rendering layer; rather than if it were left on the pop-over itself.
 * 4. Needed to calculate the "center" of the wrapped element.
 * 5. Setting to inline block to ensure that tool tip centers correctly on
 *    Inline items.
 * 6. A11y, Turning off fade animation.
 */

/* Block ******************************************************************** */
.c-tooltip {
  position: relative; /* 4 */
  display: inline-block; /* 5 */
}

.c-tooltip:hover .c-tooltip__position-helper {
  /* 3 */
  opacity: 1;
  transition-delay: .6s; /* 1 */
  transition-duration: .2s;
  transition-property: opacity;
}

@media (prefers-reduced-motion) {
  /* 6 */
  .c-tooltip:hover .c-tooltip__position-helper {
    transition-duration: 0s;
  }
}

/* Elements ***************************************************************** */
/* stylelint-disable-next-line no-descending-specificity */
.c-tooltip__position-helper {
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 0; /* 3 */
  pointer-events: none;
  position: absolute;
  right: 0;
  top: calc(100% + 2px);
  transition-property: opacity;
  user-select: none;
  width: 100%;
}

.c-tooltip__pop-over {
  font-size: .9em;
  padding: .25em .6em;
  white-space: nowrap;

  /* cosmetics */
  background-color: var(--color-utility-pure);
  border-radius: var(--radius-corners);
  border: 1px solid var(--color-utility-milk);
  box-shadow: 0 .2em .2em 0 var(--color-effect-ghost);
}

.c-tooltip__pop-over::after,
.c-tooltip__pop-over::before {
  /* cosmetics */
  border-style: solid;
  border-width: 0 5px 8.7px 5px;
  content: '';
  height: 0;
  left: calc(50% - 4.5px);
  position: absolute;
  width: 0;
}

.c-tooltip__pop-over::before {
  /* cosmetics */
  border-color: transparent transparent var(--color-utility-milk) transparent;
  bottom: 100%;
}

.c-tooltip__pop-over::after {
  /* cosmetics */
  border-color: transparent transparent var(--color-utility-pure) transparent;
  bottom: calc(100% - 1px);
}
