/*******************************************************************************
* BarGraph
***************************************************************************** */

/* Notes:
 * 1. reset browser's default figure styles
 */

/* Block ******************************************************************** */
.c-bar-chart {
  /* Local Variables */
  --bar-color--highlight: var(--color-primary-cool);
  --bar-color: var(--color-utility-water);

  margin-left: 0; /* 1 */
  margin-right: 0; /* 1 */
  padding: 1em;
  user-select: none;
}

/* Elements ***************************************************************** */
.c-bar-chart__wrapper {
  padding-left: .25em;
  padding-right: .25em;
  position: relative;

  /* cosmetics */
  border-bottom: 2px solid var(--color-effect-ghost);
}

.c-bar-chart__chart-position {
  height: 100%;
  position: absolute;
  top: 0;
  width: 0;

  /* cosmetics */
  border-left: 1px solid red;
}

.c-bar-chart__bar-background {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.c-bar-chart__y-axis-tick {
  height: 0;
  left: 0;
  position: absolute;
  right: 0;

  /* cosmetics */
  border-top: 1px solid var(--color-utility-milk);
}

.c-bar-chart__graph {
  display: flex;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
}

.c-bar-chart__bar-wrapper {
  flex: 1 1 100%;
  list-style: none;
  margin: 0;
  max-width: 3em;
  padding: 0;
  position: relative;
}

.c-bar-chart__bar-wrapper:hover {
  /* cosmetics */
  background-color: var(--color-effect-ghost);
}

.c-bar-chart__bar {
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;

  /* cosmetics */
  background-color: var(--bar-color);
  border-left: 1px solid var(--color-primary-cool-semi-transparent);
  border-radius: var(--radius-corners) var(--radius-corners) 0 0;
  border-right: 1px solid var(--color-primary-cool-semi-transparent);
  border-top: 1px solid var(--color-primary-cool-semi-transparent);
}

.c-bar-chart__bar--highlight {
  background-color: var(--bar-color--highlight);
}

.c-bar-chart__bar-button {
  align-items: flex-end;
  border: 0;
  display: flex;
  height: 100%;
  padding: 0;
  width: 100%;

  /* cosmetics */
  background-color: inherit;
}

.c-bar-chart__bar-button:focus {
  /* cosmetics */
  outline: 1px solid inherit;
}

.c-bar-chart__bar-label {
  bottom: -2em;
  display: block;
  position: absolute;
  text-align: center;
  width: 100%;

  /* cosmetics */
  color: var(--color-utility-dove);
}

.c-bar-chart__value {
  display: block;
  position: absolute;
  left: -.3em;
  right: -.3em;
  text-align: center;
  top: -1.5em;
}

.c-bar-chart__bar-wrapper + .c-bar-chart__bar-wrapper {
  margin-left: .5em;
}

.c-bar-chart__x-axis-label {
  margin-top: 2.5em;
  text-align: center;
}
