/*******************************************************************************
* ToolBar
***************************************************************************** */

/* Notes:
 * 1. a11y; Turning off animations for users that prefers reduced motion
 */

/* Block ******************************************************************** */
.c-hotkey {
  display: inline-flex;
  padding-bottom: .2em;
  padding-top: .2em;
  align-items: center;
  justify-content: center;
}

.c-hotkey > * + * {
  margin-left: .25em;
}

/* Element ****************************************************************** */
.c-hotkey__key {
  display: inline-flex;
  align-items: center;
  font-size: .8em;
  padding: 0 .75em;
  vertical-align: middle;
  transform: translateY(0);
  min-height: 2em;

  /* cosmetics */
  background-color: var(--color-utility-milk);
  border-radius: var(--radius-corners);
  border: 1px solid var(--color-effect-ghost);
  box-shadow: 0 .2em 0 0 var(--color-effect-ghost);
  color: var(--color-utility-charcoal);
  font-weight: bold;
}

/* Modifier ***************************************************************** */
.c-hotkey--animated .c-hotkey__key {
  /* cosmetics */
  animation-delay: 1s;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: button-press;
}

@media (prefers-reduced-motion) {
  /* 1 */
  .c-hotkey--animated .c-hotkey__key {
    animation-play-state: paused;
  }
}

@keyframes button-press {
  0% {
    box-shadow: 0 .2em 0 0 var(--color-effect-ghost);
    transform: translateY(0);
  }

  3% {
    box-shadow: 0 0 0 0 var(--color-effect-ghost);
    transform: translateY(.2em);
  }

  4% {
    box-shadow: 0 .2em 0 0 var(--color-effect-ghost);
    transform: translateY(0);
  }

  100% {
    box-shadow: 0 .2em 0 0 var(--color-effect-ghost);
    transform: translateY(0);
  }
}
