/*******************************************************************************
* page-indicator
***************************************************************************** */

/*
 * 1. Ensuring that page-indicators can't be selected.
 */

/* Block ******************************************************************** */
.c-page-indicator {
  display: flex;
}

.c-page-indicator > * + * {
  margin-left: 1em;
}

/* Element ****************************************************************** */
.c-page-indicator__dot {
  align-items: center;
  display: flex;
  font-size: .6em;
  height: 2em;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
  transition: background-color .2s, color .2s;
  user-select: none; /* 1 */
  width: 2em;

  /* cosmetics */
  background-color: var(--color-utility-milk);
  border-radius: 100px;
  border: none;
  font-weight: bold;
}

.c-page-indicator__dot:hover {
  /* cosmetics */
  background-color: var(--color-primary-cool-semi-transparent);
}

.c-page-indicator__dot:focus {
  outline: none;
  padding-left: 1em;
  padding-right: 1em;
  width: auto;

  /* cosmetics */
  border: 1px solid var(--color-primary-cool);
}

.c-page-indicator__dot-label {
  max-width: 0;
  overflow-x: hidden;
  white-space: nowrap;
}

.c-page-indicator__dot:focus .c-page-indicator__dot-label {
  max-width: 10em;
}

/* Modifier **************************************************************** */
.c-page-indicator__dot--active {
  background-color: var(--color-primary-cool);
  color: var(--color-utility-pure);
}

.c-page-indicator__dot--active:hover {
  background-color: var(--color-primary-cool);
}
