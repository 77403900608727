/*******************************************************************************
* Modal
***************************************************************************** */

/*
 * 1. Funky units here to ensure that the "X" aligned correctly.
 * 2. Resetting the margin top, to overide the Lobotomized owl selector *+*
 * 3. Overiding child button. It' might be worth making a direct style to the
 *    Button component later. --close?
 * 4. Leaving this here because it's declared in the dom.
 * 5. This exsists to reset the tool-tip's font-size back to "normal".
*/

/* Block ******************************************************************** */
.c-modal {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* Elements ***************************************************************** */
.c-modal__dialouge {
  max-width: 50vw !important;
  min-width: 20em;
  padding-bottom: 4px;
  position: relative;

  /* cosmetics */
  background-color: var(--color-utility-pure);
  border-radius: var(--radius-corners);
}

.c-modal__body {
  /* 4 */
}

.c-modal__title {
  padding: 1em;
  padding-bottom: .5em;
  text-align: center;

  /* cosmetics */
  border-bottom: 1px solid var(--color-utility-milk);
}

.c-modal__content {
  margin-right: 1px;
  max-height: calc(80vh - 4em);
  overflow-y: scroll;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: .5em;
  padding-bottom: 1em;

  /* cosmetics */
  scrollbar-color: var(--color-utility-milk) var(--color-utility-pure);
  scrollbar-width: thin;
}

.c-modal__content::-webkit-scrollbar {
  /* cosmetics */
  width: 8px;
}

.c-modal__content::-webkit-scrollbar-track {
  /* 2 */
}

.c-modal__content::-webkit-scrollbar-thumb {
  /* 2 */

  /* cosmetics */
  background-color: var(--color-utility-milk);
  border-radius: var(--radius-corners);
  border: 1px solid var(--var--color-utility-pure);
}

.c-modal__close {
  display: flex;
  align-items: center;
  user-select: none;
  font-size: 1.5em; /* 1 */
  margin-top: 0; /* 2 */
  position: absolute;
  right: .65em; /* 1 */
  top: .3em; /* 1 */
}

.c-modal_tooltip { /* 5 */
  font-size: .9rem;
}

.c-modal__close-button__description {
  display: inline-block;
  width: 0;
  overflow-x: hidden;
  white-space: nowrap;
  font-size: .4em;
}

.c-modal__close-button {
  background-color: transparent; /* 3 */
  border: none;
  padding: 0; /* 3 */

  /* cosmetics */
  transition: color .2s, transform .2s;
}

.c-modal__close-button:focus {
  /* cosmetics */
  color: var(--color-primary-cool);
  outline: none;
}

.c-modal__close-button:focus .c-modal__close-button__description,
.c-modal__close-button:active .c-modal__close-button__description {
  width: auto;
}

.c-modal__close-button:hover,
.c-modal__close-button:active {
  /* cosmetics */
  color: var(--color-primary-cool);
}
