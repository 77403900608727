/*******************************************************************************
* Variables
***************************************************************************** */
:root {
  /* Callout Colors */
  --color-primary-cool-light: #f8f8fe;
  --color-primary-cool-semi-transparent: rgba(11, 117, 203, .15);
  --color-primary-cool: rgb(0, 105, 168);

  /* Utility Colors */
  --color-utility-black: rgb(0, 0, 0);
  --color-utility-charcoal: rgb(35, 35, 35);
  --color-utility-dove: rgb(100, 100, 100);
  --color-utility-milk: rgb(238, 238, 238);
  --color-utility-pure: rgb(255, 255, 255);
  --color-utility-water: #d4e4f5;

  /* Effects */
  --color-effect-ghost: rgba(0, 0, 0, .1);

  /* Settings */
  --radius-corners: 4px;
  --typeface: helvetica, arial, sans-serif;
}

/*******************************************************************************
* Resets
***************************************************************************** */
*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  /* cosmetics */
  outline: 3px solid var(--color-primary-cool);
}

body {
  font-family: var(--typeface);
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* Hide scrollbar in Edge */
body::-webkit-scrollbar {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
  padding: 0;
}

blockquote,
pre {
  margin: 0;
}

input,
textarea,
select,
button {
  font-size: inherit;
}

hr {
  /* cosmetics */
  border-bottom: 2px solid var(--color-utility-milk);
  border: 0;
  margin-bottom: 1em;
}

textarea::-webkit-input-placeholder {
  /* cosmetics */
  color: rgba(0, 0, 0, .5);
}

a {
  /* cosmetics */
  color: var(--color-primary-cool);
  text-decoration: none;
}

a:hover {
  /* cosmetics */
  box-shadow: inset 0 -2px 0 0 var(--color-primary-cool);
}

a:focus {
  outline: inherit;
}

button {
  font-family: inherit;
}

/*******************************************************************************
* Utility
***************************************************************************** */

/* Notes:
 * 1. Leaving this empty for now
 */

/* Font Sizes *************************************************************** */
.u-font-size--lin {
  /* 1 */
}

.u-font-size--marcus {
  font-size: 1.6em;
}

.u-font-size--maria {
  font-size: 1.2em;
}

.u-font-size--saya {
  font-size: .7em;
}

.u-font-size--billy {
  font-size: .9em;
}

.u-font-size--willie {
  /* 1 */
}

.u-font-size--petra {
  /* 1 */
}

/* Hiding ******************************************************************* */
.u-hide--visually {
  border: 0 !important;
  clip-path: inset(50%) !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
