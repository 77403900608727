/*******************************************************************************
* Outline Item
***************************************************************************** */

/*
 * 1. Leaving Blank for future dev usage, if needed.
 */

/* Block ******************************************************************** */
.c-panel-item {
  /* 1 */
}

/* Element ****************************************************************** */
.c-panel-item__number {
  margin-right: .5em;

  /* cosmetics */
  font-weight: bold;
}

/* Modifier ***************************************************************** */

/* No modifers here, yet. */
