/*******************************************************************************
* Theme
***************************************************************************** */

/* Notes
 * 1. vertical background for the word count gutter.
 *    TODO: How will we scope this?
 * 2. Style tab stops of lettering snippet; Subject is not styled for caption
 *    nor sfx because subject cannot be tabbed into for those lettering types.
*/

/* Light Theme ************************************************************** */
.cm-s-comic-writer-light .cm-page {
  font-size: 1.5em;

  /* cosmetics */
  color: var(--color-primary-cool);
  font-weight: bold;
}

.cm-s-comic-writer-light .cm-panel {
  /* cosmetics */
  color: var(--color-utility-charcoal);
  font-weight: bold;
}

.cm-s-comic-writer-light .cm-dialogue {
  /* cosmetics */
  color: var(--color-utility-charcoal);
}

.cm-s-comic-writer-light .cm-sfx {
  /* cosmetics */
  color: var(--color-utility-charcoal);
}

.cm-s-comic-writer-light .cm-caption {
  /* cosmetics */
  color: var(--color-utility-charcoal);
}

/* 2 */
.active-lettering-snippet .cm-lettering-modifier,
.active-lettering-snippet .cm-lettering-content,
.active-lettering-snippet.cm-dialogue .cm-lettering-subject {
  /* cosmetics */
  background-color: var(--color-utility-milk);
}

.cm-s-comic-writer-light .cm-lettering-bold,
.cm-s-comic-writer-light .cm-paragraph-bold {
  /* cosmetics */
  font-weight: bold;
  text-decoration: underline;
}
