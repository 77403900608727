/*******************************************************************************
* Insights
***************************************************************************** */

/* Notes:
 * 1. This is not 50%, it would overflow the container otherwise.
 */

/* Block ******************************************************************** */
.c-insights {
  column-gap: 1em;
  display: grid;
  grid-template-columns: repeat(2, 49%); /* 1 */
  grid-template-rows: auto;
  padding: 1em;
  row-gap: 1em;

  /* cosmetics */
  border-top: 1px solid var(--color-utility-milk);
}
