/* Notes:
 * 1. This fills in the 4 px gap between the toolbar's bottom border and the
 *    sidebar's line.
 */

.c-editor-tool-bar {
  position: relative;

  /* cosmetics */
  border-bottom: 1px solid var(--color-utility-milk);
  font-size: .85em;
}

.c-editor-tool-bar::before {
  /* 1 */
  content: '';
  display: block;
  height: 0;
  position: absolute;
  right: 100%;
  top: 100%;
  width: 4px;

  /* cosmetics */
  border-bottom: 1px solid var(--color-utility-milk);
}
