/*******************************************************************************
* Outline Item
***************************************************************************** */

/* Notes:
 * 1.
 */

/* Block ******************************************************************** */
.c-spread-item {
  /* cosmetics */
  color: var(--color-primary-cool);
  font-weight: bold;
  transition: color .1s;
}

/* Element ****************************************************************** */

/* No Elements here, yet. */

/* Modifier ***************************************************************** */
.c-spread-item--current {
  /* cosmetics */
  color: var(--color-primary-cool);
}
