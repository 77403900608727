/*******************************************************************************
* Button
***************************************************************************** */

/*
 * 1. Ensuring that buttons can't be selected.
 */

/* Block ******************************************************************** */
.c-button {
  padding: .4em .7em .4em .7em;
  transition: background-color .2s;
  user-select: none; /* 1 */
  vertical-align: middle;

  /* cosmetics */
  background: transparent;
  border-radius: var(--radius-corners);
  border: 1px solid var(--color-utility-milk);
  color: var(--color-utility-charcoal);
}

.c-button:hover {
  /* cosmetics */
  background-color: var(--color-utility-milk);
}

.c-button:focus {
  /* cosmetics */
  border: 1px solid var(--color-primary-cool);
  outline: none;
}

/* Element ****************************************************************** */

/* Modifier **************************************************************** */
.c-button--primary {
  /* cosmetics */
  background-color: var(--color-primary-cool-semi-transparent);
  border: 1px solid transparent;
  box-shadow: inset 0 0 0 50px transparent;
  color: var(--color-primary-cool);
}

.c-button--primary:hover {
  /* cosmetics */
  background-color: var(--color-primary-cool-semi-transparent);
  box-shadow: inset 0 0 0 50px var(--color-primary-cool-semi-transparent);
}
