/*******************************************************************************
* Split View
***************************************************************************** */

/* Notes:
 * 1. Leaving this here for future development and reference.
 */

/* Block ******************************************************************** */
.isola-c-split-view {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.isola-c-split-view .gutter-horizontal {
  /* cosmetics */
  border-left: 1px solid var(--color-primary-cool-semi-transparent);
  cursor: ew-resize;
  position: relative;
  transition: border-left .1s;
  background-color: var(--color-utility-pure);
}

.isola-c-split-view .gutter-horizontal::before {
  /* cosmetics */
  background-color: var(--color-primary-cool-semi-transparent);
  content: '';
  display: block;
  height: 4em;
  position: absolute;
  right: 0;
  top: calc(50% - (4em * .5));
  width: 2px;
  transition: background-color .1s;
}

.isola-c-split-view .gutter-horizontal:hover,
.isola-c-split-view .gutter-horizontal:active,
.isola-c-split-view .gutter-horizontal:focus {
  /* cosmetics */
  border-left: 1px solid var(--color-primary-cool);
}

.isola-c-split-view .gutter-horizontal:hover::before,
.isola-c-split-view .gutter-horizontal:active::before,
.isola-c-split-view .gutter-horizontal:hover:focus {
  /* cosmetics */
  background-color: var(--color-primary-cool);
  height: 4.5em;
  top: calc(50% - (4.5em * .5));
}

/* Elements ***************************************************************** */
.c-split-view__sidebar {
  /* 1 */
}
