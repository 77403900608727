.c-tool-bar {
  display: flex;
  padding: .4em .25em;
}

.c-tool-bar > * + * {
  padding-left: .5rem;
}

.c-tool-bar__spacer {
  flex: 1 1 100%;
}

.c-tool-bar__item {
  flex: 0 0 auto;
}
