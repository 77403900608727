/*******************************************************************************
* Code Mirror
***************************************************************************** */

/* Notes:
 * 4.  Turning off "focus" outline for the editor.
 *     TODO: What's the right way to make this a11y without it looking like total
 *          shit?
 * 5.  Resetting z-index to create a new stacking context for this Component.
 * 6.  Panel Count line widget
 * 7.  Removing default gutter styling.
 * 8.  make the placeholder more placeholder-y.
 * 9.  magic number for toolbar, that offesets it. If you change the size of the
 *     tool bar, you might want to change this (the em #) second number is main
 *     menu
 * 10. over-riding default background color to transparent. Ensureing that you
 *     See the drop-shadow on scroll.
 * 11. This is to beat the js scrollbar in the stacking context.
*/

/* Block ******************************************************************** */
.c-codemirror {
  flex: 1 1 100%;
  height: calc(100vh - 2.5em - 1.71em); /* 9 */
  width: 100%;
  z-index: 0; /* 5 */
  position: relative;
}

.c-codemirror::before {
  content: '';
  position: absolute;
  bottom: calc(100% - 10px);
  left: -4px;
  height: 10px;
  width: calc(100% + 4px);
  background:
    linear-gradient(
      to bottom,
      var(--color-effect-ghost) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  opacity: 0;
  transition: opacity .5s;
  pointer-events: none;
  z-index: 7; /* 11 */
}

/* Modifier ***************************************************************** */
.c-codemirror--is-scrolled::before {
  opacity: .8;
}

/* Overides ***************************************************************** */
.CodeMirror {
  font-family: var(--typeface);
  background-color: transparent; /* 10 */
}

pre.CodeMirror-placeholder.CodeMirror-line-like {
  /* 8 */

  /* cosmetics */
  color: var(--color-utility-dove);
}

.CodeMirror-sizer {
  max-width: 50em;
  margin-right: auto;
}

.CodeMirror *:focus {
  /* 4 */
  outline: none;
}

.CodeMirror-gutter-elt {
  padding-right: .5em;
  text-align: right;

  /* cosmetics */
  color: var(--color-utility-dove);
}

.CodeMirror-gutters {
  /* 7 */
  background-color: transparent;
  border-right: none;
}

/* Gutters */
.empty-gutter,
.word-counts {
  display: inline-block;
  padding-right: .5em;
  padding-top: .1em;
  width: 2em;
}

.word-count--page {
  display: inline-block;
  padding-top: .3em;
}

.panel-count {
  /* 6 */
  bottom: calc(100% + .4em);
  position: absolute;
  right: 0;
  user-select: none;

  /* cosmetics */
  color: var(--color-utility-dove);
}

/* Scrollbar **************************************************************** */
.CodeMirror-simplescroll-horizontal div,
.CodeMirror-simplescroll-vertical div {
  /* cosmetics */
  background: var(--color-utility-milk);
  border-radius: var(--radius-corners);
  border: 1px solid var(--color-utility-pure);
}

.CodeMirror-simplescroll-horizontal,
.CodeMirror-simplescroll-vertical {
  /* cosmetics */
  background: var(--color-utility-pure);
}

.CodeMirror-simplescroll-horizontal {
  /* cosmetics */
  height: 8px;
}

.CodeMirror-simplescroll-vertical {
  /* cosmetics */
  width: 8px;
}
